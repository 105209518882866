/* eslint-disable */
const NameCustomization = () =>
  import('../pages/products/names/NameCustomization');
const FrameCustomization = () =>
  import('../pages/products/frames/FrameCustomization');
const ProductList = () => import('../pages/products/ProductList');
const HangingBirthFrame = () =>
  import('../pages/products/frames/HangingBirthFrameCustomization');
const CustomFrameCustomization = () =>
  import('../pages/products/frames/CustomFrameCustomization');
const FramePhraseCustomization = () =>
  import('../pages/products/frames/FramePhraseCustomization');
const PhraseCustomization = () =>
  import('../pages/products/decorations/PhraseCustomization');
const GiftFrame = () => import('../pages/products/frames/GiftFrame');

export default [
  // Names
  {
    path: '/produit/prenoms-3d',
    name: 'product-name',
    component: ProductList,
    props: {
      title: 'Prénoms 3d',
      description:
        "Créez le prénom 3D qui s'intégrera parfaitement à votre salon ou la chambre de bébé. C'est aussi une <br />décoration fantastique pour une baby shower ou un baptême. <br /><br />Les prénoms 3D personnalisés Widddi existent en 4 hauteurs différentes : 10 cm, 8 cm, 6cm et 3 cm.",
      tags: ['prenoms-3d'],
      articles: [
        {
          src: require('../../public/img/prenoms/prenom-6.jpg'),
          name: 'Prénom 3d personnalisé',
          variant: '8cm de haut',
          list: ['àpd. 2€ la lettre'],
          link: '/produit/prenoms-3d/8-cm-de-haut',
        },
        {
          src: require('../../public/img/prenoms/prenom-12.jpg'),
          name: 'Prénom 3d personnalisé',
          variant: '6cm de haut',
          list: ['àpd. 1,5€ la lettre'],
          link: '/produit/prenoms-3d/6-cm-de-haut',
        },
      ],
    },
  },
  {
    path: '/produit/prenoms-3d/6-cm-de-haut',
    name: 'product-name-6-height',
    component: NameCustomization,
    props: {
      src: require('../../public/img/prenoms/prenom-12.jpg'),
      type: 'Prénom 3d personnalisé 6cm de haut',
      description:
        "Widddi vous permet de créer facilement un prénom ou un mot en 3D. Passez par les 4 étapes ci-dessous et choisissez la police d'écriture, la couleur, et l'épaisseur (décoration à poser, décoration murale ou plaque de porte).",
      size: 6,
      tags: ['prenom-3d-6cm'],
    },
  },
  {
    path: '/produit/prenoms-3d/8-cm-de-haut',
    name: 'product-name-8-height',
    component: NameCustomization,
    props: {
      src: require('../../public/img/prenoms/prenom-6.jpg'),
      type: 'Prénom 3d personnalisé 8cm de haut',
      description:
        "Widddi vous permet de créer facilement un prénom ou un mot en 3D. Passez par les 4 étapes ci-dessous et choisissez la police d'écriture, la couleur, et l'épaisseur (décoration à poser, décoration murale ou plaque de porte).",
      size: 8,
      tags: ['prenom-3d-8cm'],
    },
  },

  // Birthday frames
  {
    path: '/produit/cadre-de-naissance',
    name: 'product-baby-frame',
    component: ProductList,
    props: {
      title: 'Cadres de naissance personnalisés',
      description:
        "<strong>Les cadres de naissance</strong> Widddi indiquent le <strong>prénom</strong>, la <strong>date</strong> et l'<strong>heure de naissance</strong>, le <strong>poids</strong> et la <strong>taille</strong> <br />de bébé. Ils comportent aussi une photo de format 10x15cm que vous pouvez changer quand vous le désirez.",
      tags: ['cadre-de-naissance'],
      articles: [
        {
          src: require('../../public/img/vertical/cadre-vertical-52.jpg'),
          name: 'Cadre de naissance à poser',
          variant: 'pour photo verticale 10x15',
          link: '/produit/cadre-de-naissance/format-photo-verticale',
        },
        {
          src: require('../../public/img/horizontal/cadre-horizontal-22.jpg'),
          name: 'Cadre de naissance à poser',
          variant: 'pour photo horizontale 15x10',
          link: '/produit/cadre-de-naissance/format-photo-horizontale',
        },
        {
          src: require('../../public/img/cadre-perso-horizontal/cadre-perso-horizontal-17.jpg'),
          name: 'Cadre de naissance simple à poser',
          variant: 'pour photo horizontale 15x10',
          link:
            '/produit/cadre-de-naissance/cadre-de-naissance-horizontal-simple',
        },
        {
          src: require('../../public/img/jumeaux/cadre-naissance-jumeaux-personnalise.jpg'),
          name: 'Cadre de naissance à poser',
          variant: 'spécial jumeaux 15x10',
          link: '/produit/cadre-de-naissance/cadre-jumeaux',
        },
        {
          src: require('../../public/img/hanging-vertical/cadre-vertical-suspendu-6.jpg'),
          name: 'Cadre de naissance à suspendre',
          variant: 'Pour photo verticale 10x15cm',
          link:
            '/produit/cadre-a-suspendre/cadre-naissance-photo-verticale',
        },
        {
          src: require('../../public/img/hanging-horizontal/cadre-horizontal-suspendu-10.jpg'),
          name: 'Cadre de naissance à suspendre',
          variant: 'Pour photo horizontale 15x10cm',
          link:
            '/produit/cadre-a-suspendre/cadre-naissance-photo-horizontale',
        },
        {
          src: require('../../public/img/rainbow/cadre-naissance-personnalise-arc-en-ciel.jpg'),
          name: 'Cadre de naissance',
          variant: 'Arc-en-ciel',
          link:
            '/produit/cadre-de-naissance/cadre-de-naissance-arc-en-ciel',
        },
      ],
    },
  },
  {
    path: '/produit/cadre-de-naissance/format-photo-verticale',
    name: 'product-frame-vertical',
    component: FrameCustomization,
    props: {
      src: require('../../public/img/vertical/cadre-vertical-52.jpg'),
      type: 'Cadre de naissance vertical',
      description:
        'Créez votre cadre de naissance en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-naissance-vertical'],
    },
  },
  {
    path: '/produit/cadre-de-naissance/format-photo-horizontale',
    name: 'product-frame-horizontal',
    component: FrameCustomization,
    props: {
      src: require('../../public/img/horizontal/cadre-horizontal-22.jpg'),
      type: 'Cadre de naissance horizontal',
      description:
        'Créez votre cadre de naissance en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-naissance-horizontal'],
    },
  },
  {
    path: '/produit/cadre-de-naissance/cadre-jumeaux',
    name: 'product-frame-twins',
    component: FrameCustomization,
    props: {
      src: require('../../public/img/jumeaux/cadre-naissance-jumeaux-personnalise.jpg'),
      type: 'Cadre de naissance pour jumeaux',
      description:
        'Créez votre cadre de naissance en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-naissance-pour-jumeaux'],
    },
  },
  {
    path:
      '/produit/cadre-de-naissance/cadre-de-naissance-horizontal-simple',
    name: 'product-frame-horizontal-simple',
    component: () =>
      import('../pages/products/frames/SimplestFrameCustomization'),
    props: {
      src: require('../../public/img/cadre-perso-horizontal/cadre-perso-horizontal-17.jpg'),
      type: 'Cadre de naissance horizontal simple',
      hiddenType: 'cadre-de-naissance-horizontal-simple',
      description:
        'Créez votre cadre de naissance en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-naissance-horizontal-simple'],
    },
  },
  {
    path: '/produit/cadre-de-naissance/cadre-de-naissance-arc-en-ciel',
    name: 'rainbow-birth-frame',
    component: () => import('../pages/products/frames/RainbowFrame'),
    props: {
      src: require('../../public/img/rainbow/cadre-naissance-personnalise-arc-en-ciel.jpg'),
      type: 'Cadre de naissance Arc-en-ciel',
      hiddenType: 'rainbow-frame',
      description:
        "Un souvenir coloré, unique et original de la naissance de votre bébé. <br /><br />Indiquez la date et l’heure de naissance, la taille et le poids de bébé. <br /> Choisissez une police d'écriture pour le prénom et 3 couleurs pour créer son cadre de naissance arc-en-ciel.",
      tags: ['cadre-de-naissance-arc-en-ciel'],
    },
  },

  // Asking Frame
  {
    path: '/produit/cadeau-de-demande-parrain-marraine',
    name: 'product-asking-gift',
    component: ProductList,
    props: {
      title: 'Cadeaux de demande parrain/marraine',
      description:
        'Un cadeau original pour faire votre demande à la marraine et au parrain de votre bébé !',
      tags: ['cadeau-de-demande-parrain-marraine'],
      articles: [
        {
          src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-parrain-cadre-photo-horizontale.jpeg'),
          name: 'Cadre de demande',
          variant: 'parrain - horizontal',
          link:
            '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-parrain-horizontal',
        },
        {
          src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-marraine-cadre-photo-horizontale.jpeg'),
          name: 'Cadre de demande',
          variant: 'marraine - horizontal',
          link:
            '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-marraine-horizontal',
        },
        {
          src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-parrain-cadre-photo.jpg'),
          name: 'Cadre de demande',
          variant: 'parrain - vertical',
          link:
            '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-parrain',
        },
        {
          src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-marraine-cadre-photo.jpg'),
          name: 'Cadre de demande',
          variant: 'marraine - vertical',
          link:
            '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-marraine',
        },
      ],
    },
  },
  {
    path:
      '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-marraine-horizontal',
    name: 'asking-gift-godmother-horizontal',
    component: () => import('../pages/products/frames/AskingFrame'),
    props: {
      src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-marraine-cadre-photo-horizontale.jpeg'),
      type: 'Cadre photo de demande marraine - horizontal',
      hiddenType: 'cadre-de-demande-horizontal',
      description:
        'Un cadeau original pour faire votre demande à la marraine de votre bébé ! <br> Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
      tags: ['cadre-demande-marraine-horizontal'],
    },
  },
  {
    path:
      '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-parrain-horizontal',
    name: 'asking-gift-godfather-horizontal',
    component: () => import('../pages/products/frames/AskingFrame'),
    props: {
      src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-parrain-cadre-photo-horizontale.jpeg'),
      type: 'Cadre photo de demande parrain - horizontal',
      hiddenType: 'cadre-de-demande-horizontal',
      description:
        'Un cadeau original pour faire votre demande à la marraine de votre bébé ! <br> Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
      tags: ['cadre-demande-parrain-horizontal'],
    },
  },
  {
    path:
      '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-marraine',
    name: 'asking-gift-godmother',
    component: () => import('../pages/products/frames/AskingFrame'),
    props: {
      src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-marraine-cadre-photo.jpg'),
      type: 'Cadre photo de demande marraine - vertical',
      hiddenType: 'cadre-de-demande',
      description:
        'Un cadeau original pour faire votre demande à la marraine de votre bébé ! <br> Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
      tags: ['cadre-demande-marraine'],
    },
  },
  {
    path:
      '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-parrain',
    name: 'asking-gift-godfather',
    component: () => import('../pages/products/frames/AskingFrame'),
    props: {
      src: require('../../public/img/cadeau-parrain-marraine/cadeau-demande-parrain-cadre-photo.jpg'),
      type: 'Cadre photo de demande parrain - vertical',
      hiddenType: 'cadre-de-demande',
      description:
        'Un cadeau original pour faire votre demande à la marraine de votre bébé ! <br> Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
      tags: ['cadre-demande-parrain'],
    },
  },

  // Baptisme frames
  {
    path: '/produit/cadre-de-bapteme',
    name: 'product-frame-baptism',
    component: ProductList,
    props: {
      title: 'Cadres de baptême',
      description:
        '<strong>Les cadres de baptême</strong> Widddi indiquent le <strong>prénom du bébé</strong> et la <strong>date de son baptême</strong>, vous pouvez également ajouter le <strong>prénom du parrain et celui de la marraine</strong>. <br />Ils comportent aussi une photo de format 10x15cm que vous pouvez changer quand vous le désirez.',
      tags: ['cadre-de-bapteme'],
      articles: [
        {
          src: require('../../public/img/bapteme/cadre-bapteme-personnalise-arche-rose.jpg'),
          name: 'Cadre de baptême',
          variant: 'arche',
          link: '/produit/cadre-de-bapteme/cadre-de-bapteme-arche',
        },
        {
          src: require('../../public/img/bapteme/cadre-bapteme-22.jpg'),
          name: 'Cadre de baptême',
          variant: 'classique',
          link: '/produit/cadre-de-bapteme/format-photo-verticale',
        },
        {
          src: require('../../public/img/bapteme/cadre-bapteme-personnalise-15.jpg'),
          name: 'Cadre de baptême',
          variant: 'parrain marraine',
          link:
            '/produit/cadre-de-bapteme/format-photo-verticale-parrain-marraine',
        },
      ],
    },
  },
  {
    path: '/produit/cadre-de-bapteme/cadre-de-bapteme-arche',
    name: 'product-frame-baptism-ark',
    component: () => import('../pages/products/frames/BaptismArkFrame'),
    props: {
      src: require('../../public/img/bapteme/cadre-bapteme-personnalise-arche-rose.jpg'),
      type: 'Cadre de baptême arche',
      hiddenType: 'baptism-ark',
      description:
        "À la recherche d’un <strong>souvenir de baptême</strong> pour votre bébé ? Le <strong>cadre de baptême arche</strong> est à la fois simple, esthétique et original. Avec ce joli <strong>cadre photo personnalisé</strong> vous pouvez choisir la police d'écriture pour le prénom de votre bébé ainsi que la couleur du cadre et indiquer la date du baptême. “MON BAPTEME” est indiqué avec un magnifique et subtil effet gravé.",
      tags: ['cadre-de-bapteme-arche'],
    },
  },
  {
    path: '/produit/cadre-de-bapteme/format-photo-verticale',
    name: 'product-frame-baptism-classic',
    component: () =>
      import('../pages/products/frames/BaptismFrameCustomization'),
    props: {
      src: require('../../public/img/bapteme/cadre-bapteme-22.jpg'),
      type: 'Cadre de baptême',
      hiddenType: 'baptism-classic',
      description:
        'Créez votre cadre de baptême en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-bapteme-classique'],
    },
  },
  {
    path:
      '/produit/cadre-de-bapteme/format-photo-verticale-parrain-marraine',
    name: 'product-frame-baptism-godfather-godmother',
    component: () =>
      import('../pages/products/frames/BaptismFrameGodCustomization'),
    props: {
      src: require('../../public/img/bapteme/cadre-bapteme-personnalise-15.jpg'),
      type: 'Cadre de baptême parrain marraine',
      hiddenType: 'baptism-godparents',
      description:
        'Créez votre cadre de baptême en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-bapteme-parrain-marraine'],
    },
  },

  // Christmas
  {
    path: '/produit/noel/decorations-de-noel',
    name: 'christmas-decorations',
    component: ProductList,
    props: {
      title: 'Décorations de Noël',
      description:
        'Créez des <strong>décorations de Noël personnalisées</strong> et uniques avec Widddi. Pour votre propre déco ou à offrir ! C’est LE <strong>cadeau de Noël original</strong>.',
      tags: ['decorations-de-noel'],
      articles: [
        {
          src: require('../../public/img/christmas/boule-nuit-etoilee-1.jpeg'),
          name: 'Boule de Noël personnalisée',
          variant: 'Nuit étoilée',
          link: '/produit/noel/decorations-de-noel/nuit-etoilee',
        },
        {
          src: require('../../public/img/christmas/boule-de-noel-couronne-1.jpg'),
          name: 'Boule de Noël personnalisée',
          variant: 'Couronne de feuilles',
          link:
            '/produit/noel/decorations-de-noel/boule-de-noel-couronne-de-feuilles',
        },
        {
          src: require('../../public/img/christmas/boule-arche-7.jpg'),
          name: 'Boule de Noël personnalisée',
          variant: 'Arche avec flocon',
          link: '/produit/noel/decorations-de-noel/arche-de-noel-flocon',
        },
        {
          src: require('../../public/img/christmas/boule-perso-8.jpg'),
          name: 'Boule de Noël personnalisée',
          variant: 'Originale',
          link: '/produit/noel/decorations-de-noel/boule-de-noel',
        },
        {
          src: require('../../public/img/christmas/cerf-13.jpg'),
          name: 'Boule de Noël personnalisée',
          variant: 'Cerf',
          link: '/produit/noel/decorations-de-noel/boule-de-noel-cerf',
        },
      ],
    },
  },
  {
    path: '/produit/noel/decorations-de-noel/boule-de-noel',
    name: 'christmas-ornament',
    component: () =>
      import(
        '../pages/products/christmas/christmas-decoration/ChristmasDecorationCustomization'
      ),
    props: {
      src: require('../../public/img/christmas/boule-perso-8.jpg'),
      type: 'Boule de Noël',
      description:
        'Allez-vous créer un souvenir du premier Noël de votre bébé ? Ou alors un cadeau à offrir à votre meilleur(e) ami(e) ? Plutôt mignon ou plutôt marrant ? <br>À vous de choisir ! Créez votre "boule" de Noël personnalisée en suivant les 4 étapes ci-dessous.',
      tags: ['boule-de-noel'],
    },
  },
  {
    path: '/produit/noel/decorations-de-noel/boule-de-noel-cerf',
    name: 'deer',
    component: () =>
      import(
        '../pages/products/christmas/deer-decoration/DeerDecorationCustomization'
      ),
    props: {
      src: require('../../public/img/christmas/cerf-13.jpg'),
      type: 'Boule de Noël Cerf',
      description:
        'Créez votre boule de Noël personnalisée en suivant les 4 étapes ci-dessous.',
      tags: ['boule-de-noel-cerf'],
    },
  },
  {
    path: '/produit/noel/decorations-de-noel/arche-de-noel-flocon',
    name: 'ark-snowflake',
    component: () =>
      import('../pages/products/christmas/ArkDecorationCustomization'),
    props: {
      src: require('../../public/img/christmas/boule-arche-7.jpg'),
      type: 'Arche de Noël',
      description:
        'Créez votre boule de Noël personnalisée en suivant les 5 étapes ci-dessous.',
      tags: ['arche-de-noel-flocon'],
    },
  },
  {
    path:
      '/produit/noel/decorations-de-noel/boule-de-noel-couronne-de-feuilles',
    name: 'leaf-crown-christmas-ornament',
    component: () =>
      import(
        '../pages/products/christmas/christmas-decoration/ChristmasFloralDecorationCustomization'
      ),
    props: {
      src: require('../../public/img/christmas/boule-de-noel-couronne-1.jpg'),
      type: 'Boule de Noël Couronne de feuilles',
      description:
        'Notre nouvelle boule de Noël mobile vous émerveillera avec ses deux cercles intérieurs qui tournent sur eux-même. <br/><br/> Personnalisez-la en suivant les 4 étapes ci-dessous.',
      tags: ['boule-de-noel-couronne-de-feuilles'],
    },
  },
  {
    path: '/produit/noel/decorations-de-noel/nuit-etoilee',
    name: 'starry-night',
    component: () =>
      import('../pages/products/christmas/StarDecorationCustomization'),
    props: {
      src: require('../../public/img/christmas/boule-nuit-etoilee-1.jpeg'),
      type: 'Nuit étoilée',
      description:
        'La boule de Noël Widddi “Nuit étoilée” vous enveloppe dans la nuit magique de Noël. <br /><br /> Faites tourner le disque arrière et accompagnez le père Noël et ses rennes parmi les étoiles, en route pour sa tournée autour du monde.',
      tags: ['nuit-etoilee'],
    },
  },

  // Gift cards
  {
    path: '/produit/cadeaux/cartes-cadeaux',
    name: 'coupon',
    component: () => import('../pages/products/coupons/CouponProduct'),
    props: {
      src: require('../../public/img/carte-cadeau-widddi.jpg'),
      type: 'Carte cadeau',
      description:
        "Vous souhaitez offrir un cadeau Widddi à une maman ou des futurs parents mais vous manquez de temps ?<br />Optez pour la carte cadeau imprimable Widddi ! <br /><br />Choisissez le montant, passez commande puis imprimez le pdf que vous recevrez par e-mail. Ajoutez un petit mot sur votre carte cadeau Widddi puis glissez-la dans une jolie enveloppe. <br /><br />Les futurs parents pourront choisir le produit, la couleur et la police d'écriture qu'ils préfèrent.",
      tags: [''],
    },
  },
  {
    path: '/produit/cadeaux/cartes-cadeaux/:code',
    name: 'get-coupon',
    component: () => import('../pages/products/coupons/GetCoupon'),
  },

  // Decorations
  {
    path: '/produit/decorations',
    name: 'decorations',
    component: ProductList,
    props: {
      title: 'Décorations personnalisées',
      description:
        'À la recherche d’un cadeau original ? Ou d’un objet unique pour votre intérieur ? Créez votre décoration personnalisée avec Widddi.',
      tags: ['decorations'],
      articles: [
        {
          src: require('../../public/img/infinite/infini-1.jpg'),
          name: 'Décoration personnalisée',
          variant: 'Infini',
          link: '/produit/decorations/infini',
        },
        {
          src: require('../../public/img/phrase/deco-phrase-1.jpg'),
          name: 'Décoration personnalisée à poser',
          variant: '"L\'amour de ma vie"',
          link: '/produit/decorations/decoration-a-poser-amour-de-ma-vie',
        },
      ],
    },
  },
  {
    path: '/produit/decorations/infini',
    name: 'product-infinite',
    component: () =>
      import('../pages/products/decorations/InfiniteProduct'),
    props: {
      src: require('../../public/img/infinite/infini-1.jpg'),
      type: 'Décoration infini',
      description:
        'Deux prénoms, une date, un amour infini. Créez cette décoration personnalisée pour votre mariage ou alors en souvenir de votre rencontre. <br /><br />Créez votre décoration infini en 5 étapes.',
      tags: ['infini'],
    },
  },
  {
    path: '/produit/decorations/decoration-a-poser-amour-de-ma-vie',
    name: 'deco-phrase',
    component: PhraseCustomization,
    props: {
      src: require('../../public/img/phrase/deco-phrase-1.jpg'),
      type: 'Décoration personnalisée à poser "L\'amour de ma vie"',
      hiddenType: 'deco-phrase',
      description:
        'Quand on sera vieux je te dirai : "Tu vois que tu étais l\'amour de ma vie". <br />Un petit cadeau personnalisé pour votre moité, choisissez la date, les initiales et la couleur. ',
      tags: ['decoration-a-poser-amour-de-ma-vie'],
    },
  },

  // Hanging frames
  {
    path: '/produit/cadre-a-suspendre',
    name: 'hanging-frame',
    component: ProductList,
    props: {
      title: 'Cadres à susprendre',
      description:
        'Créez un <strong>cadre photo à suspendre</strong> unique grâce à Widddi. Choisissez votre modèle favori et <strong>personnalisez-le</strong> très facilement. Ces cadres sont prévus pour une photo 15x10cm.',
      tags: ['cadre-a-suspendre'],
      articles: [
        {
          src: require('../../public/img/flora/cadre-flora-1.jpg'),
          name: 'Cadre photo à suspendre',
          variant: 'Flora',
          link: '/produit/cadre-a-suspendre/cadre-photo-flora',
        },
        {
          src: require('../../public/img/tipi/cadre-tipi-1.jpg'),
          name: 'Cadre photo à suspendre',
          variant: 'Tipi',
          link: '/produit/cadre-a-suspendre/cadre-photo-tipi',
        },
        {
          src: require('../../public/img/hanging-vertical/cadre-vertical-suspendu-6.jpg'),
          name: 'Cadre de naissance à suspendre',
          variant: 'Pour photo verticale 10x15cm',
          link:
            '/produit/cadre-a-suspendre/cadre-naissance-photo-verticale',
        },
        {
          src: require('../../public/img/hanging-horizontal/cadre-horizontal-suspendu-10.jpg'),
          name: 'Cadre de naissance à suspendre',
          variant: 'Pour photo horizontale 15x10cm',
          link:
            '/produit/cadre-a-suspendre/cadre-naissance-photo-horizontale',
        },
      ],
    },
  },
  {
    path: '/produit/cadre-a-suspendre/cadre-photo-flora',
    name: 'product-flora-frame',
    component: () =>
      import('../pages/products/frames/FloraFrameCustomization'),
    props: {
      src: require('../../public/img/flora/cadre-flora-1.jpg'),
      type: 'Cadre photo Flora',
      description:
        'À votre tour de créer votre cadre photo Flora. Doux et romantique avec ses jolis motifs floraux, ce cadre à suspendre est une magnifique façon de mettre en valeurs l’une de vos plus belles photos.',
      tags: ['cadre-photo-flora'],
    },
  },
  {
    path: '/produit/cadre-a-suspendre/cadre-photo-tipi',
    name: 'product-tipi-frame',
    component: () =>
      import('../pages/products/frames/TipiFrameCustomization'),
    props: {
      src: require('../../public/img/tipi/cadre-tipi-1.jpg'),
      type: 'Cadre photo Tipi',
      description:
        'Bienvenue chez les indiens ! Personnalisez votre cadre photo Tipi avec un prénom ou un mot et la couleur de votre choix.',
      tags: ['cadre-photo-tipi'],
    },
  },
  {
    path: '/produit/cadre-a-suspendre/cadre-naissance-photo-horizontale',
    name: 'product-hanging-birth-frame-horizontal',
    component: HangingBirthFrame,
    props: {
      src: require('../../public/img/hanging-horizontal/cadre-horizontal-suspendu-10.jpg'),
      type: 'Cadre de naissance à suspendre pour photo horizontale',
      hiddenType: 'hanging-horizontal',
      description:
        'Créez votre cadre de naissance en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-naissance-a-suspendre-horizontal'],
    },
  },
  {
    path: '/produit/cadre-a-suspendre/cadre-naissance-photo-verticale',
    name: 'product-hanging-birth-frame-vertical',
    component: HangingBirthFrame,
    props: {
      src: require('../../public/img/hanging-vertical/cadre-vertical-suspendu-6.jpg'),
      type: 'Cadre de naissance à suspendre pour photo verticale',
      hiddenType: 'hanging-vertical',
      description:
        'Créez votre cadre de naissance en suivant les 5 étapes ci-dessous',
      tags: ['cadre-de-naissance-a-suspendre-vertical'],
    },
  },

  // Custom frames
  {
    path: '/produit/cadre-photo-personnalise',
    name: 'custom-frame',
    component: ProductList,
    props: {
      title: 'Cadres photo personnalisés',
      description:
        'Créez un <strong>cadre photo</strong> unique grâce à Widddi. Choisissez votre modèle favori et <strong>personnalisez-le</strong> très facilement. Ces cadres sont prévus pour une photo 15x10cm.',
      tags: ['cadre-photo-personnalise'],
      articles: [
        {
          src: require('../../public/img/rainbow/cadre-arc-en-ciel-personnalise-1.jpg'),
          name: 'Cadre personnalisé',
          variant: 'Arc-en-ciel',
          link:
            '/produit/cadre-photo-personnalise/cadre-personnalise-arc-en-ciel',
        },
        {
          src: require('../../public/img/cadre-perso-vertical/cadre-perso-vertical-21.jpg'),
          name: 'Cadre personnalisé à poser',
          variant: 'Pour photo verticale 10x15cm',
          link:
            '/produit/cadre-photo-personnalise/cadre-a-poser-photo-verticale',
        },
        {
          src: require('../../public/img/cadre-perso-horizontal/cadre-perso-horizontal-31.jpg'),
          name: 'Cadre personnalisé à poser',
          variant: 'Pour photo horizontale 15x10cm',
          link:
            '/produit/cadre-photo-personnalise/cadre-a-poser-photo-horizontale',
        },
        {
          src: require('../../public/img/phrase/cadre-phrase-1.jpg'),
          name: 'Cadre personnalisé à poser',
          variant: '"L\'amour de ma vie"',
          link:
            '/produit/cadre-photo-personnalise/cadre-a-poser-amour-de-ma-vie',
        },
        {
          src: require('../../public/img/phrase/cadre-personnalise-mariage.jpg'),
          name: 'Cadre personnalisé à poser',
          variant: '"Le mariage c’est"',
          link: '/produit/cadre-photo-personnalise/cadre-de-mariage',
        },
      ],
    },
  },
  {
    path:
      '/produit/cadre-photo-personnalise/cadre-a-poser-photo-verticale',
    name: 'custom-vertical-frame',
    component: CustomFrameCustomization,
    props: {
      src: require('../../public/img/cadre-perso-vertical/cadre-perso-vertical-21.jpg'),
      type: 'Cadre personnalisé à poser pour photo verticale 10x15',
      hiddenType: 'custom-vertical-frame',
      description:
        'Créez votre cadre personnalisé en suivant les 5 étapes ci-dessous',
      tags: ['cadre-personnalise-vertical'],
    },
  },
  {
    path:
      '/produit/cadre-photo-personnalise/cadre-a-poser-photo-horizontale',
    name: 'custom-horizontal-frame',
    component: CustomFrameCustomization,
    props: {
      src: require('../../public/img/cadre-perso-horizontal/cadre-perso-horizontal-31.jpg'),
      type: 'Cadre personnalisé à poser pour photo horizontale 15x10',
      hiddenType: 'custom-horizontal-frame',
      description:
        'Créez votre cadre personnalisé en suivant les 5 étapes ci-dessous',
      tags: ['cadre-personnalise-horizontal'],
    },
  },
  {
    path:
      '/produit/cadre-photo-personnalise/cadre-a-poser-amour-de-ma-vie',
    name: 'frame-phrase',
    component: FramePhraseCustomization,
    props: {
      src: require('../../public/img/phrase/cadre-phrase-1.jpg'),
      type: 'Cadre personnalisé à poser "L\'amour de ma vie"',
      hiddenType: 'frame-phrase',
      description:
        'Quand on sera vieux je te dirai : "Tu vois que tu étais l\'amour de ma vie". <br />Un cadeau unique pour une personne unique ! <br />Personnalisez votre cadre photo avec la date de votre rencontre/mariage et vos initiales.',
      tags: ['cadre-personnalise-amour-de-ma-vie'],
    },
  },
  {
    path: '/produit/cadre-photo-personnalise/cadre-de-mariage',
    name: 'cadre-de-mariage',
    component: () =>
      import('../pages/products/decorations/WeddingPhraseCustomization'),
    props: {
      src: require('../../public/img/phrase/cadre-personnalise-mariage.jpg'),
      type: 'Cadre de mariage',
      hiddenType: 'cadre-de-mariage',
      description:
        'Le mariage c’est la volonté à deux de créer l’unique. <br /><br />Personnalisez ce cadre photo avec la date du mariage et les initiales des amoureux.',
      tags: ['cadre-de-mariage'],
    },
  },
  {
    path:
      '/produit/cadre-photo-personnalise/cadre-personnalise-arc-en-ciel',
    name: 'rainbow-frame-custom',
    component: () => import('../pages/products/frames/RainbowFrame'),
    props: {
      src: require('../../public/img/rainbow/cadre-arc-en-ciel-personnalise-1.jpg'),
      type: 'Cadre personnalisé Arc-en-ciel',
      hiddenType: 'rainbow-frame-custom',
      description:
        "Un souvenir coloré, unique et original. <br /><br />Indiquez le message que vous souhaitez, choisissez une police d'écriture pour le prénom et 3 couleurs pour créer votre cadre arc-en-ciel.",
      tags: ['cadre-personnalise-arc-en-ciel'],
    },
  },

  // Cooking
  {
    path: '/produit/materiel-creatif',
    name: 'creative-craft',
    component: ProductList,
    props: {
      title: 'Matériel créatif',
      description:
        'Widddi vous accompagnes dans vos projets créatifs avec du matériel unique, fabriqué en petite série et que vous ne trouverez donc pas chez tous vos concurrents ! Offrez-vous une touche d’originalité grâce à la flexibilité de l’impression 3D.',
      tags: ['materiel-creatif'],
      articles: [
        {
          src: require('../../public/img/biscuits/emporte-pieces.jpg'),
          variant: 'Emporte-pièce personnalisés',
          link: '/produit/materiel-creatif/emporte-piece-pour-biscuits',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/set-tampons-alphabet-poterie-biscuits.jpg'),
          variant: 'Sets tampon alphabet',
          link: '/produit/materiel-creatif/set-tampon-alphabet',
        },
      ],
    },
  },
  {
    path: '/produit/materiel-creatif/emporte-piece-pour-biscuits',
    name: 'cookie-cutters',
    component: ProductList,
    props: {
      title: 'Emporte-pièce pour biscuits',
      description:
        'Chaque emporte-pièce existe en 4 tailles (XS, S, M, L) pour s’adapter parfaitement à vos besoins.',
      tags: ['emporte-piece-pour-biscuits'],
      action: 'Choisir la taille',
      articles: [
        {
          src: require('../../public/img/biscuits/ep-arche.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Arche',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/arche',
        },
        {
          src: require('../../public/img/biscuits/ep-carre-coins-casses.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Carré coins cassés',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/carre-coins-casses',
        },
        {
          src: require('../../public/img/biscuits/ep-cristal.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Cristal',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/cristal',
        },
        {
          src: require('../../public/img/biscuits/ep-oval.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Oval',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/oval',
        },
        {
          src: require('../../public/img/biscuits/ep-rectangle-4angles-arrondis.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Rectangle angles arrondis',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/rectangle-4angles-arrondis',
        },
        {
          src: require('../../public/img/biscuits/ep-rectangle-1angle-arrondi.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Rectangle 1 angle arrondi',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/rectangle-1angle-arrondi',
        },
        {
          src: require('../../public/img/biscuits/ep-rectangle-2angles-arrondis.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Rectangle 2 angles arrondis',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/rectangle-2angles-arrondis',
        },
        {
          src: require('../../public/img/biscuits/ep-rectangle-gonfle.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Rectangle gonflé',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/rectangle-gonfle',
        },
        {
          src: require('../../public/img/biscuits/ep-coeur.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Coeur',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/coeur',
        },
        {
          src: require('../../public/img/biscuits/ep-nuage.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Nuage',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/nuage',
        },
        {
          src: require('../../public/img/biscuits/ep-baleine.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Baleine',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/baleine',
        },
        {
          src: require('../../public/img/biscuits/ep-carre-festonne.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Carré festonné',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/carre-festonne',
        },
        {
          src: require('../../public/img/biscuits/ep-chat.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Chat',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/chat',
        },
        {
          src: require('../../public/img/biscuits/ep-couronne.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Couronne',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/couronne',
        },
        {
          src: require('../../public/img/biscuits/ep-diamant.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Diamant',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/diamant',
        },
        {
          src: require('../../public/img/biscuits/ep-etoile.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Étoile',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/etoile',
        },
        {
          src: require('../../public/img/biscuits/ep-fleur-1.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Fleur 1',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/fleur-1',
        },
        {
          src: require('../../public/img/biscuits/ep-fleur-2.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Fleur 2',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/fleur-2',
        },
        {
          src: require('../../public/img/biscuits/ep-fleur-3.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Fleur 3',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/fleur-3',
        },
        {
          src: require('../../public/img/biscuits/ep-hexagone.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Hexagone',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/hexagone',
        },
        {
          src: require('../../public/img/biscuits/ep-rectangle-festonne.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Rectangle festonné',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/rectangle-festonne',
        },
        {
          src: require('../../public/img/biscuits/ep-rond-festonne.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Rond festonné',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/rond-festonne',
        },
        {
          src: require('../../public/img/biscuits/ep-baroque.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Baroque',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/baroque',
        },
        {
          src: require('../../public/img/biscuits/ep-arc-en-ciel.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Arc-en-ciel',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/arc-en-ciel',
        },
        {
          src: require('../../public/img/biscuits/ep-cadre.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Cadre',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/cadre',
        },
        {
          src: require('../../public/img/biscuits/ep-body.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Body de bébé',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/body',
        },
        {
          src: require('../../public/img/biscuits/ep-montgolfiere.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Montgolfière',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/montgolfiere',
        },
        {
          src: require('../../public/img/biscuits/ep-ours.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Ours',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/ours',
        },
        {
          src: require('../../public/img/biscuits/ep-fanion.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Fanion',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/fanion',
        },
        {
          src: require('../../public/img/biscuits/ep-tipi.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Tipi',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/tipi',
        },
        {
          src: require('../../public/img/biscuits/ep-renard.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Renard',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/renard',
        },
        {
          src: require('../../public/img/biscuits/ep-amande.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Amande',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/amande',
        },
        {
          src: require('../../public/img/biscuits/ep-sorciere.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Chapeau de sorcière',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/chapeau-sorciere',
        },
        {
          src: require('../../public/img/biscuits/ep-chauve-souris.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Chauve-souris',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/chauve-souris',
        },
        {
          src: require('../../public/img/biscuits/ep-fantome.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Fantôme',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/fantome',
        },
        {
          src: require('../../public/img/biscuits/ep-chene.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Feuille de chêne',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/feuille-chene',
        },
        {
          src: require('../../public/img/biscuits/ep-erable.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: "Feuille d'érable",
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/feuille-erable',
        },
        {
          src: require('../../public/img/biscuits/ep-marronnier.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Feuille de marronnier',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/feuille-marronnier',
        },
        {
          src: require('../../public/img/biscuits/ep-feuille.jpg'),
          name: 'Emporte-pièce pour biscuits',
          variant: 'Feuille',
          link:
            '/produit/materiel-creatif/emporte-piece-pour-biscuits/feuille',
        },
      ],
    },
  },
  {
    path: '/produit/materiel-creatif/emporte-piece-pour-biscuits/:name',
    name: 'cookie-cutter',
    component: () => import('../pages/products/cooking/CookieCutter'),
    props: {
      // src: require('../../public/img/biscuits/emporte-pieces.jpg'),
      type: 'Emporte-pièce pour biscuits',
      hiddenType: 'cookie-cutter',
      description:
        'Choisissez la taille de votre emporte-pièce baleine parmi les 4 tailles disponibles (XS - S - M - L).',
      tags: ['emporte-piece-pour-biscuits'],
    },
  },

  // Alphabet Stamps
  {
    path: '/produit/materiel-creatif/set-tampon-alphabet',
    name: 'alphabet-stamps',
    component: ProductList,
    props: {
      title: 'Sets de tampons alphabet',
      description:
        "Découvrez nos sets de tampons alphabet polyvalents, parfaits pour personnaliser votre poterie ou décorer vos biscuits. <br />Créez des messages uniques et artistiques avec des lettres élégantes et précises. <br />Offrez une touche personnelle à vos créations artisanales dès aujourd'hui !",
      tags: ['set-tampon-alphabet'],
      articles: [
        {
          src: require('../../public/img/materiel-creatif/creativo/kit-tampons-alphabet-creativo.jpg'),
          name: 'Set de tampons alphabet',
          variant: 'Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo',
        },
      ],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo',
    name: 'creativo-stamps',
    component: ProductList,
    props: {
      title: 'Set de tampons alphabet Creativo',
      description:
        "Transformez vos créations en pièces uniques avec notre set de tampons alphabet Creativo ! <br />Des lettres cursives idéales pour la poterie ou la décoration de biscuits, ces tampons vous permettent de personnaliser facilement chaque pièce. <br /><br />La police d'écriture Creativo est à la fois simple et élégante. <br />Il s’agit d’une police d'écriture cursive libre de droits que vous pouvez utiliser sans problème dans vos projets artistiques et commerciaux.",
      tags: ['set-tampon-alphabet-creativo'],
      articles: [
        {
          src: require('../../public/img/materiel-creatif/creativo/set-tampons-alphabet-creativo-complet.jpg'),
          name: 'Set complet',
          variant: 'Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/set-complet-creativo',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/jeu-lettres-tampons-cursives-creativo-majuscules.jpg'),
          name: 'Jeu de majuscules',
          variant: 'Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/majuscules-creativo',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/jeu-lettres-tampons-cursives-creativo-minuscules.jpg'),
          name: 'Jeu de minuscules',
          variant: 'Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/minuscules-creativo',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/jeu-lettres-tampons-cursives-creativo-speciaux-chiffres.jpg'),
          name: 'Caractères spéciaux & Chiffres',
          variant: 'Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/speciaux-chiffres-creativo',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/plateaux-rangement-majuscules-creativo.jpg'),
          name: 'Plateau de rangement - Majuscules',
          variant: 'Accessoires Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/plateau-rangement-creativo-majuscules',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/plateaux-rangement-minuscules-creativo.jpg'),
          name: 'Plateau de rangement - Minuscules',
          variant: 'Accessoires Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/plateau-rangement-creativo-minuscules',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/plateaux-rangement-speciaux-creativo.jpg'),
          name: 'Plateau de rangement - Spéciaux & Chiffres',
          variant: 'Accessoires Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/plateau-rangement-creativo-speciaux-chiffres',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/set-tampons-alphabet-poterie-biscuits.jpg'),
          name: 'Réglette d\'alignement',
          variant: 'Accessoires Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/reglette-alignement',
        },
        {
          src: require('../../public/img/materiel-creatif/creativo/set-lettres-tampons-cursives-creativo-accessoires.jpg'),
          name: 'Couvercle personnalisé + réglette',
          variant: 'Accessoires Creativo',
          link:
            '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/couvercle-personnalise',
        },
      ],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/set-complet-creativo',
    name: 'full-set-creativo',
    component: () =>
      import('../pages/products/creative/CreativoFullSet'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/kit-tampons-alphabet-creativo.jpg'),
      type: 'Set complet Creativo',
      hiddenType: 'creativo-stamps',
      description:
        'Découvrez notre set de tampons alphabet complet Creativo pour poterie et biscuits ! <br />Il inclut les majuscules, les minuscules, des caractères spéciaux et les chiffres de 0 à 9, avec plateaux de rangement, couvercle personnalisé et réglette pour un alignement parfait. <br />Libérez votre créativité avec facilité et précision !',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/majuscules-creativo',
    name: 'uppercase-creativo',
    component: () =>
      import('../pages/products/creative/CreativoUppercase'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/jeu-lettres-tampons-cursives-creativo-majuscules.jpg'),
      type: 'Jeu de majuscules Creativo',
      hiddenType: 'creativo-stamps',
      description:
        'Notre jeu de tampons alphabet majuscules Creativo pour poterie et biscuits se compose de lettres cursives élégantes. <br /> <br />À associer avec le jeu de lettres minuscules ou à utiliser seul pour marquer des initiales ou créer des monogrammes.',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/minuscules-creativo',
    name: 'lowercase-creativo',
    component: () =>
      import('../pages/products/creative/CreativoLowercase'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/jeu-lettres-tampons-cursives-creativo-minuscules.jpg'),
      type: 'Jeu de minuscules Creativo',
      hiddenType: 'creativo-stamps',
      description:
        "Ce jeu de tampons alphabet minuscules Creativo comprend déjà plusieurs minuscules en double pour vous permettent d'écrire une multitude de mots. <br />Idéal pour décorer poteries et biscuits avec style. <br />Maximisez votre potentiel créatif en achetant deux jeux pour une flexibilité illimitée dans vos projets !",
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/speciaux-chiffres-creativo',
    name: 'specials-numbers-creativo',
    component: () => import('../pages/products/creative/CreativoSpecial'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/jeu-lettres-tampons-cursives-creativo-speciaux-chiffres.jpg'),
      type: 'Caractères spéciaux et chiffres Creativo',
      hiddenType: 'creativo-stamps',
      description:
        'Ce jeu de tampons Creativo pour poterie et biscuits, comprend des caractères spéciaux, des chiffres de 0 à 9 et même un adorable petit coeur! <br />Parfait pour personnaliser chaque création avec précision et originalité, il offre une touche unique à vos projets. <br />Exprimez-vous sans limites et créez des pièces uniques qui captivent et enchantent !',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/plateau-rangement-creativo-majuscules',
    name: 'accessories-creativo-uppercase',
    component: () => import('../pages/products/creative/CreativoBox'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/plateaux-rangement-majuscules-creativo.jpg'),
      type: 'Plateau de rangement pour majuscules',
      hiddenType: 'creativo-stamps',
      description: 'Optimisez votre espace de travail avec nos plateaux de rangement sur mesure, spécialement conçus pour notre kit de tampons alphabets Creativo imprimé en 3D. <br />Organisez vos outils avec efficacité.',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/plateau-rangement-creativo-minuscules',
    name: 'accessories-creativo-lowercase',
    component: () => import('../pages/products/creative/CreativoBox'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/plateaux-rangement-minuscules-creativo.jpg'),
      type: 'Plateau de rangement pour minuscules',
      hiddenType: 'creativo-stamps',
      description: 'Optimisez votre espace de travail avec nos plateaux de rangement sur mesure, spécialement conçus pour notre kit de tampons alphabets Creativo imprimé en 3D. <br />Organisez vos outils avec efficacité.',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/plateau-rangement-creativo-speciaux-chiffres',
    name: 'accessories-creativo-specials',
    component: () => import('../pages/products/creative/CreativoBox'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/plateaux-rangement-speciaux-creativo.jpg'),
      type: 'Plateau de rangement pour caractères spéciaux et chiffres',
      hiddenType: 'creativo-stamps',
      description: 'Optimisez votre espace de travail avec nos plateaux de rangement sur mesure, spécialement conçus pour notre kit de tampons alphabets Creativo imprimé en 3D. <br />Organisez vos outils avec efficacité.',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/reglette-alignement',
    name: 'accessories-creativo-bar',
    component: () => import('../pages/products/creative/CreativoBar'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/set-tampons-alphabet-poterie-biscuits.jpg'),
      type: 'Réglette d\'alignement',
      hiddenType: 'creativo-stamps',
      description: 'Alignez parfaitement vos caractères pour des projets créatifs parfaits et une précision sans faille.',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },
  {
    path:
      '/produit/materiel-creatif/set-tampon-alphabet/set-tampon-alphabet-creativo/couvercle-personnalise',
    name: 'accessories-creativo-lid',
    component: () => import('../pages/products/creative/CreativoLid'),
    props: {
      src: require('../../public/img/materiel-creatif/creativo/set-lettres-tampons-cursives-creativo-accessoires.jpg'),
      type: 'Couvercle personnalisé + réglette',
      hiddenType: 'creativo-stamps',
      description: 'Finalisez la boite de rangement de vos tampons alphabets avec un couvercle personnalisé !<br /> Il permet de maintenir tous vos plateaux Widddi ensemble grâce à sa ficelle naturelle élégante.<br /> Il comprend aussi un espace pour ranger la réglette d’alignement incluse.<br /> C’est une bonne manière d’organiser vos outils avec efficacité et de perfectionner chaque projet avec une précision sans faille.',
      tags: ['set-tampon-alphabet-creativo'],
    },
  },

  // Small frames
  {
    path: '/produit/petit-cadre/cadeau-de-remerciement',
    name: 'product-gift-frame',
    component: ProductList,
    props: {
      title: 'Cadeaux de remerciement',
      description:
        'Ces petits cadres sont une belle façon de remercier les personnes importantes dans la vie de votre enfant. <br />Vous pouvez y glisser une petite photo ou un mini dessin. Un cadeau original qui plaira à coup sûr.',
      tags: ['cadeau-remerciement'],
      articles: [
        {
          src: require('../../public/img/mini-cadres/enseigner-2.jpg'),
          name: 'Cadeau de remerciement',
          variant: 'enseignant',
          link:
            '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-enseignant',
        },
        {
          src: require('../../public/img/mini-cadres/nounou-1.jpg'),
          name: 'Cadeau de remerciement',
          variant: 'nounou',
          link:
            '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-nounou',
        },
        {
          src: require('../../public/img/mini-cadres/aesh-1.jpg'),
          name: 'Cadeau de remerciement',
          variant: 'AESH',
          link:
            '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-aesh',
        },
      ],
    },
  },
  {
    path:
      '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-enseignant',
    name: 'product-teacher-frame-gift',
    component: GiftFrame,
    props: {
      src: require('../../public/img/mini-cadres/enseigner-2.jpg'),
      type: 'Cadeau de remerciement enseignant',
      hiddenType: 'product-gift-frame',
      description:
        "Aimer, inspirer, guider, motiver : Les missions essentielles d'un bon enseignant. Créez un cadeau original pour remercier le maître ou la maîtresse de votre enfant.",
      tags: ['cadeau-remerciement-enseignant'],
    },
  },
  {
    path:
      '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-nounou',
    name: 'product-babysitter-frame-gift',
    component: GiftFrame,
    props: {
      src: require('../../public/img/mini-cadres/nounou-1.jpg'),
      type: 'Cadeau de remerciement nounou',
      hiddenType: 'product-gift-frame',
      description:
        "Amour, patience, douceur, tendresse. <br /><br />Nounou, marraine, mamie,.. Créez un petit cadeau original pour remercier celles et ceux qui s'occupent tellement bien de votre bout de chou.",
      tags: ['cadeau-remerciement-nounou'],
    },
  },
  {
    path:
      '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-aesh',
    name: 'product-aesh-frame-gift',
    component: GiftFrame,
    props: {
      src: require('../../public/img/mini-cadres/aesh-1.jpg'),
      type: 'Cadeau de remerciement AESH',
      hiddenType: 'product-gift-frame',
      description:
        "Créez un cadeau original pour remercier l'AESH de votre enfant.",
      tags: ['cadeau-remerciement-aesh'],
    },
  },
];
