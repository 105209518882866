function findIndexInData(data, property, value) {
  const l = data.length;
  for (let i = 0; i < l; i++) {
    if (data[i][property] === value) {
      return i;
    }
  }
  return -1;
}

const prenderedRoutes = [
  {
    name: '/',
    title: 'Widddi avec 3d - Accueil',
    description: 'Widddi, création d’objets personnalisés imprimés en 3d, prénom 3d, cadre et faire-part de naissance. Un cadeau idéal à offrir.',
    image: 'logo-widddi.jpg',
  },
  {
    name: '/contact',
    title: 'Widddi avec 3d - Contact',
    description: 'Contactez-nous',
    image: 'logo-widddi.jpg',
  },
  {
    name: '/faq',
    title: 'Widddi avec 3d - FAQ',
    description: 'Foire aux questions',
    image: 'logo-widddi.jpg',
  },
  {
    name: '/conditions-generales',
    title: 'Widddi avec 3d - Conditions générales de vente',
    description: 'Conditions générales de ventes',
    image: 'logo-widddi.jpg',
  },
  {
    name: '/checkout',
    title: 'Widddi avec 3d - Panier',
    description: 'Panier',
    image: 'logo-widddi.jpg',
  },
  {
    name: '/login',
    title: 'Widddi avec 3d - Connexion',
    description: 'Connexion',
    image: 'logo-widddi.jpg',
  },
  {
    name: '/profil',
    title: 'Widddi avec 3d - Mon compte',
    description: 'Mon compte',
    image: 'logo-widddi.jpg',
  },
  {
    name: '/produit/cadre-de-naissance',
    title: 'Widddi avec 3d - Les cadres de naissance',
    description: 'Les cadres de naissance indiquent prénom, date, heure de naissance, poids et taille de bébé. Une photo de format 10x15cm que vous pouvez changer.',
    image: 'cadre-vertical-52.jpg',
  },
  {
    name: '/produit/cadre-de-naissance/format-photo-verticale',
    title: 'Widddi avec 3d - Cadre de naissance vertical',
    description: 'Le cadre de naissance vertical indique prénom, date, heure de naissance, poids et taille de bébé. Une photo de format 10x15cm que vous pouvez changer.',
    image: 'cadre-vertical-52.jpg',
  },
  {
    name: '/produit/cadre-de-naissance/format-photo-horizontale',
    title: 'Widddi avec 3d - Cadre de naissance horizontal',
    description: 'Le cadre de naissance horizontal indique prénom, date, heure de naissance, poids et taille de bébé. Une photo de format 15x10cm que vous pouvez changer.',
    image: 'cadre-horizontal-22.jpg',
  },
  {
    name: '/produit/cadre-de-naissance/cadre-jumeaux',
    title: 'Widddi avec 3d - Cadre de naissance spécial jumeaux',
    description: 'Le cadre de naissance pour jumeaux indique prénoms, dates, heures de naissance, poids et tailles des bébés. Une photo de format 15x10cm que vous pouvez changer.',
    image: 'cadre-naissance-jumeaux-personnalise.jpg',
  },
  {
    name: '/produit/prenoms-3d',
    title: 'Widddi avec 3d - Les prénoms 3d',
    description: 'Les prénoms 3D qui s’intégrent à votre salon ou la chambre de bébé. Une décoration fantastique pour une baby shower. Les prénoms 3D existent en différentes tailles',
    image: 'prenom-3.jpg',
  },
  {
    name: '/produit/prenoms-3d/11-cm-de-haut',
    title: 'Widddi avec 3d - Prénom 3d 11cm de haut',
    description: 'Prénom imprimé en 3d de 11cm de haut',
    image: 'prenom-3.jpg',
  },
  {
    name: '/produit/prenoms-3d/8-cm-de-haut',
    title: 'Widddi avec 3d - Prénom 3d 8cm de haut',
    description: 'Prénom imprimé en 3d de 8cm de haut',
    image: 'prenom-3.jpg',
  },
  {
    name: '/produit/prenoms-3d/6-cm-de-haut',
    title: 'Widddi avec 3d - Prénom 3d 6cm de haut',
    description: 'Prénom imprimé en 3d de 6cm de haut',
    image: 'prenom-3.jpg',
  },
  {
    name: '/produit/prenoms-3d/3-cm-de-haut',
    title: 'Widddi avec 3d - Prénom 3d 3cm de haut',
    description: 'Prénom imprimé en 3d de 3cm de haut',
    image: 'prenom-3.jpg',
  },
  {
    name: '/produit/cadre-de-bapteme',
    title: 'Widddi avec 3d - Les cadres de baptême',
    description: 'Les cadres de baptême indiquent prénom et date, vous pouvez également ajouter le prénom du parrain et de la marraine.',
    image: 'cadre-bapteme-22.jpg',
  },
  {
    name: '/produit/cadre-de-bapteme/cadre-de-bapteme-arche',
    title: 'Widddi avec 3d - Cadre de baptême arche',
    description: 'Cadre de baptême format arche',
    image: 'cadre-bapteme-personnalise-arche-rose.jpg',
  },
  {
    name: '/produit/cadre-de-bapteme/format-photo-verticale',
    title: 'Widddi avec 3d - Cadre de baptême pour photo verticale 10x15',
    description: 'Cadre de baptême format pour photo verticale 10x15cm',
    image: 'cadre-bapteme-22.jpg',
  },
  {
    name: '/produit/cadre-de-bapteme/format-photo-verticale-parrain-marraine',
    title: 'Widddi avec 3d - Cadre de baptême parrain marraine',
    description: 'Cadre de baptême format pour photo verticale 10x15cm avec les prénoms parrain et marraine',
    image: 'cadre-bapteme-personnalise-15.jpg',
  },
  {
    name: '/produit/decorations/infini',
    title: 'Widddi avec 3d - Décoration infini',
    description: 'Deux prénoms, une date, un amour infini. Créez cette décoration personnalisée pour votre mariage ou alors en souvenir de votre rencontre. Créez votre décoration infini.',
    image: 'infini-15.jpg',
  },
  {
    name: '/produit/cadre-a-suspendre',
    title: 'Widddi avec 3d - Les cadres à suspendre',
    description: 'Les cadres à suspendre.',
    image: 'cadre-flora-1.jpg',
  },
  {
    name: '/produit/cadre-a-suspendre/cadre-photo-flora',
    title: 'Widddi avec 3d - Cadre photo Flora',
    description: 'À votre tour de créer votre cadre photo Flora. Doux et romantique avec ses jolis motifs floraux, ce cadre à suspendre est une magnifique façon de mettre en valeurs l’une de vos plus belles photos.',
    image: 'cadre-flora-1.jpg',
  },
  {
    name: '/produit/cadre-a-suspendre/cadre-photo-tipi',
    title: 'Widddi avec 3d - Cadre photo Tipi',
    description: 'Bienvenue chez les indiens ! Personnalisez votre cadre photo Tipi avec un prénom ou un mot et la couleur de votre choix.',
    image: 'cadre-tipi-1.jpg',
  },
  {
    name: '/produit/cadre-a-suspendre/cadre-naissance-photo-verticale',
    title: 'Widddi avec 3d - Cadre de naissance à suspendre pour photo verticale',
    description: 'Le cadre de naissance à suspendre pour photo verticale indique prénom, date, heure de naissance, poids et taille de bébé. Une photo de format 10x15cm que vous pouvez changer.',
    image: 'hanging-vertical/cadre-vertical-suspendu-6.jpg',
  },
  {
    name: '/produit/cadre-a-suspendre/cadre-naissance-photo-horizontale',
    title: 'Widddi avec 3d - Cadre de naissance à suspendre pour photo horizontale',
    description: 'Le cadre de naissance à suspendre pour photo horizontale indique prénom, date, heure de naissance, poids et taille de bébé. Une photo de format 15x10cm que vous pouvez changer.',
    image: 'hanging-horizontal/cadre-horizontal-suspendu-10.jpg',
  },
  {
    name: '/produit/cadeaux/cartes-cadeaux',
    title: 'Widddi avec 3d - Cartes cadeaux',
    description: 'Carte cadeau imprimable Widddi ! Choisissez le montant, passez commande puis imprimez le pdf que vous recevrez par e-mail.',
    image: 'carte-cadeau-widddi.jpg',
  },
  {
    name: '/produit/noel/decorations-de-noel',
    title: 'Widddi avec 3d - Décorations de Noël',
    description: 'Créez des décorations de Noël personnalisées et uniques avec Widddi.',
    image: 'christmas/boule-perso-8.jpg',
  },
  {
    name: '/produit/noel/decorations-de-noel/boule-de-noel',
    title: 'Widddi avec 3d - Boule de Noël',
    description: 'Créez votre boule de Noël personnalisée.',
    image: 'christmas/boule-perso-8.jpg',
  },
  {
    name: '/produit/noel/decorations-de-noel/boule-de-noel-cerf',
    title: 'Widddi avec 3d - Boule de Noël Cerf',
    description: 'Créez votre boule de Noël personnalisée en forme de cerf.',
    image: 'christmas/cerf-13.jpg',
  },
  {
    name: '/produit/cadre-photo-personnalise',
    title: 'Widddi avec 3d - Cadres photo personnalisés',
    description: 'Créez votre cadre photo unique.',
    image: 'cadre-perso-horizontal/cadre-perso-horizontal-31.jpg',
  },
  {
    name: '/produit/cadre-photo-personnalise/cadre-a-poser-photo-verticale',
    title: 'Widddi avec 3d - Cadre photo vertical à poser',
    description: 'Créez votre cadre photo vertical unique.',
    image: 'cadre-perso-vertical/cadre-perso-vertical-21.jpg',
  },
  {
    name: '/produit/cadre-photo-personnalise/cadre-a-poser-photo-horizontale',
    title: 'Widddi avec 3d - Cadre photo horizontal à poser',
    description: 'Créez votre cadre photo horizontal unique.',
    image: 'cadre-perso-horizontal/cadre-perso-horizontal-31.jpg',
  },
  {
    name: '/produit/cuisine-creative',
    title: 'Widddi avec 3d - Cuisine créative',
    description: 'Emporte-pièce personnalisés naissance, mariage, anniversaire, baptême, baby shower,…',
    image: 'biscuits/biscuits-1.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits',
    description: 'Chaque emporte-pièce existe en 4 tailles (XS, S, M, L) pour s’adapter parfaitement à vos besoins.',
    image: 'biscuits/emporte-pieces.jpg',
  },
  {
    name: '/produit/cuisine-creative/tampon-personnalisé-pour-biscuits',
    title: 'Widddi avec 3d - Tampons personnalisés pour biscuits',
    description: 'Tampon pour biscuit personnalisé naissance, mariage, anniversaire, baptême, baby shower,… ',
    image: 'biscuits/tampon-date.jpg',
  },
  {
    name: '/produit/cuisine-creative/tampon-personnalisé-pour-biscuits/tampons-standards',
    title: 'Widddi avec 3d - Tampon personnalisé pour biscuits avec date',
    description: 'Créez votre tampon personnalisé pour biscuits en suivant les 4 étapes de personnalisation',
    image: 'biscuits/tampon-date.jpg',
  },
  {
    name: '/produit/cuisine-creative/tampon-personnalisé-pour-biscuits/tampons-prenom-texte',
    title: 'Widddi avec 3d - Tampon personnalisé pour biscuits avec prénom et texte',
    description: 'Créez votre tampon personnalisé pour biscuits en suivant les 4 étapes de personnalisation',
    image: 'biscuits/tampon-texte.jpg',
  },
  {
    name: '/produit/cuisine-creative/tampon-personnalisé-pour-biscuits/tampons-texte-prenom',
    title: 'Widddi avec 3d - Tampon personnalisé pour biscuits avec texte et prénom',
    description: 'Créez votre tampon personnalisé pour biscuits en suivant les 4 étapes de personnalisation',
    image: 'biscuits/tampon-texte2.jpg',
  },
  {
    name: '/produit/cuisine-creative/tampon-personnalisé-pour-biscuits/petits-tampons',
    title: 'Widddi avec 3d - Petit tampon personnalisé pour biscuits',
    description: 'Créez votre petit tampon personnalisé pour biscuits en suivant les 3 étapes de personnalisation',
    image: 'biscuits/petit-tampon.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/arche',
    title: 'Emporte-pièce pour biscuits Arche',
    description: 'Emporte-pièce pour biscuits en forme d\'arche',
    image: 'biscuits/ep-arche.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/carre-coins-casses',
    title: 'Emporte-pièce pour biscuits Carré coins cassés',
    description: 'Emporte-pièce pour biscuits en forme de carré aux coins cassés',
    image: 'biscuits/ep-carre-coins-casses.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/cristal',
    title: 'Emporte-pièce pour biscuits Cristal',
    description: 'Emporte-pièce pour biscuits en forme de cristal',
    image: 'biscuits/ep-cristal.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/oval',
    title: 'Emporte-pièce pour biscuits Oval',
    description: 'Emporte-pièce pour biscuits en forme d\'oval',
    image: 'biscuits/ep-oval.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/rectangle-4angles-arrondis',
    title: 'Emporte-pièce pour biscuits Rectangle angles arrondis',
    description: 'Emporte-pièce pour biscuits en forme de rectangle aux angles arrondis',
    image: 'biscuits/ep-rectangle-4angles-arrondis.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/rectangle-1angle-arrondi',
    title: 'Emporte-pièce pour biscuits Rectangle 1 angle arrondi',
    description: 'Emporte-pièce pour biscuits en forme de rectangle avec 1 angle arrondi',
    image: 'biscuits/ep-rectangle-1angle-arrondi.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/rectangle-2angles-arrondis',
    title: 'Emporte-pièce pour biscuits Rectangle 2 angles arrondis',
    description: 'Emporte-pièce pour biscuits en forme de rectangle avec 2 angles arrondis',
    image: 'biscuits/ep-rectangle-2angles-arrondis.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/rectangle-gonfle',
    title: 'Emporte-pièce pour biscuits Rectangle gonflé',
    description: 'Emporte-pièce pour biscuits en forme de rectangle gonflé',
    image: 'biscuits/ep-rectangle-gonfle.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/nuage',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Noeur',
    description: 'Emporte-pièce pour biscuits en forme de nuage',
    image: 'biscuits/ep-nuage.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/baleine',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Baleine',
    description: 'Emporte-pièce pour biscuits en forme de baleine',
    image: 'biscuits/ep-baleine.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/carre-festonne',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Carré festonné',
    description: 'Emporte-pièce pour biscuits en forme de carré festonné',
    image: 'biscuits/ep-carre-festonne.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/chat',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Chat',
    description: 'Emporte-pièce pour biscuits en forme de chat',
    image: 'biscuits/ep-chat.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/couronne',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Couronne',
    description: 'Emporte-pièce pour biscuits en forme de couronne',
    image: 'biscuits/ep-couronne.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/diamant',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Diamant',
    description: 'Emporte-pièce pour biscuits en forme de diamant',
    image: 'biscuits/ep-diamant.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/etoile',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Etoile',
    description: 'Emporte-pièce pour biscuits en forme de etoile',
    image: 'biscuits/ep-etoile.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/fleur-1',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Fleur 1',
    description: 'Emporte-pièce pour biscuits en forme de fleur 1',
    image: 'biscuits/ep-fleur-1.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/fleur-2',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Fleur 2',
    description: 'Emporte-pièce pour biscuits en forme de fleur 2',
    image: 'biscuits/ep-fleur-2.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/fleur-3',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Fleur 3',
    description: 'Emporte-pièce pour biscuits en forme de fleur 3',
    image: 'biscuits/ep-fleur-3.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/hexagone',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Hexagone',
    description: 'Emporte-pièce pour biscuits en forme de hexagone',
    image: 'biscuits/ep-hexagone.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/rectangle-festonne',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Rectangle festonné',
    description: 'Emporte-pièce pour biscuits en forme de rectangle festonné',
    image: 'biscuits/ep-rectangle-festonne.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/rond-festonne',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Rond festonné',
    description: 'Emporte-pièce pour biscuits en forme de rond festonné',
    image: 'biscuits/ep-rond-festonne.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/baroque',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Baroque',
    description: 'Emporte-pièce pour biscuits aux formes baroques',
    image: 'biscuits/ep-baroque.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/arc-en-ciel',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Arc-en-ciel',
    description: 'Emporte-pièce pour biscuits en forme d’arc-en-ciel',
    image: 'biscuits/ep-arc-en-ciel.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/cadre',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Cadre',
    description: 'Emporte-pièce pour biscuits en forme de cadre',
    image: 'biscuits/ep-cadre.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/body',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Body de bébé',
    description: 'Emporte-pièce pour biscuits en forme de body de bébé',
    image: 'biscuits/ep-body.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/montgolfiere',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Montgolfière',
    description: 'Emporte-pièce pour biscuits en forme de montgolfière',
    image: 'biscuits/ep-montgolfiere.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/ours',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Ours',
    description: 'Emporte-pièce pour biscuits en forme d’ours',
    image: 'biscuits/ep-ours.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/fanion',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Fanion',
    description: 'Emporte-pièce pour biscuits en forme de fanion',
    image: 'biscuits/ep-fanion.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/tipi',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Tipi',
    description: 'Emporte-pièce pour biscuits en forme de tipi',
    image: 'biscuits/ep-tipi.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/renard',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Renard',
    description: 'Emporte-pièce pour biscuits en forme de renard',
    image: 'biscuits/ep-renard.jpg',
  },

  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/amande',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Amande',
    description: 'Emporte-pièce pour biscuits en forme d\'Amande',
    image: 'biscuits/ep-amande.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/chapeau-sorciere',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Chapeau de sorcière',
    description: 'Emporte-pièce pour biscuits en forme de Chapeau de sorcière',
    image: 'biscuits/ep-sorciere.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/chauve-souris',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Chauve-souris',
    description: 'Emporte-pièce pour biscuits en forme de Chauve-souris',
    image: 'biscuits/ep-chauve-souris.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/fantome',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Fantôme',
    description: 'Emporte-pièce pour biscuits en forme de Fantôme',
    image: 'biscuits/ep-fantome.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/feuille-chene',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Feuille de chêne',
    description: 'Emporte-pièce pour biscuits en forme de Feuille de chêne',
    image: 'biscuits/ep-chene.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/feuille-erable',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Feuille d\'érable',
    description: 'Emporte-pièce pour biscuits en forme de Feuille d\'érable',
    image: 'biscuits/ep-erable.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/feuille-marronnier',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Feuille de marronnier',
    description: 'Emporte-pièce pour biscuits en forme de Feuille de marronnier',
    image: 'biscuits/ep-marronnier.jpg',
  },
  {
    name: '/produit/cuisine-creative/emporte-piece-pour-biscuits/feuille',
    title: 'Widddi avec 3d - Emporte-pièce pour biscuits Feuille',
    description: 'Emporte-pièce pour biscuits en forme de Feuille',
    image: 'biscuits/ep-feuille.jpg',
  },
  {
    name: '/produit/cadre-photo-personnalise/cadre-a-poser-amour-de-ma-vie',
    title: 'Widddi avec 3d - Cadre personnalisé à poser "L\'amour de ma vie"',
    description: 'Quand on sera vieux je te dirai : "Tu vois que tu étais l\'amour de ma vie". Un cadeau unique pour une personne unique ! Personnalisez votre cadre photo avec la date de votre rencontre/mariage et vos initiales.',
    image: 'phrase/cadre-phrase-1.jpg',
  },
  {
    name: '/produit/decorations/decoration-a-poser-amour-de-ma-vie',
    title: 'Widddi avec 3d - Décoration personnalisée à poser "L\'amour de ma vie"',
    description: 'Quand on sera vieux je te dirai : "Tu vois que tu étais l\'amour de ma vie". Un petit cadeau personnalisé pour votre moité, choisissez la date, les initiales et la couleur. ',
    image: 'phrase/deco-phrase-1.jpg',
  },
  {
    name: '/produit/decorations',
    title: 'Widddi avec 3d - Décorations personnalisées',
    description: 'À la recherche d’un cadeau original ? Ou d’un objet unique pour votre intérieur ? Créez votre décoration personnalisée avec Widddi.',
    image: 'infinite/infini-1.jpg',
  },
  {
    name: '/produit/cuisine-creative/set-emporte-piece-et-tampon',
    title: 'Widddi avec 3d - Set emporte-pièce et tampon pour biscuit',
    description: 'Les sets emporte-pièce + tampon de Widddi vous permettent de créer des magnifiques biscuits très facilement.',
    image: 'biscuits/set-saint-valentin.jpg',
  },
  {
    name: '/produit/cuisine-creative/set-emporte-piece-et-tampon/set-grand-biscuit-emporte-pièce-tampons-coeur-puzzle-avec-initiales',
    title: 'Widddi avec 3d - Set grand biscuit emporte-pièce + 2 tampons Coeur puzzle avec initiales',
    description: 'L’emporte-pièce coeur puzzle est parfait pour vos biscuits de Saint-Valentin ou de mariage. Deux moitiés qui s’assemblent parfaitement, qui se complètent, faites l’une pour l’autre.',
    image: 'biscuits/thumb-set-saint-valentin.jpg',
  },
  {
    name: '/produit/cuisine-creative/set-emporte-piece-et-tampon/set-biscuit-emporte-piece-tampon-love',
    title: 'Widddi avec 3d - Set biscuit emporte-pièce + tampon Love',
    description: 'Le set emporte-pièce + tampon Love est simple et efficace, il aura toujours beaucoup de succès. Existe en 2 tailles.',
    image: 'biscuits/categorie-set.jpg',
  },
  {
    name: '/produit/cuisine-creative/set-emporte-piece-et-tampon/set-biscuit-4-emporte-piece-tampon-love',
    title: 'Widddi avec 3d - Set biscuit 4 emporte-pièce Lettres LOVE',
    description: '4 emporte-pièce lettres pour former le mot LOVE. Idéal pour des biscuits de mariage ou de Saint-Valentin. Existe en 2 tailles.',
    image: 'biscuits/set-love2.jpg',
  },
  {
    name: '/produit/noel/decorations-de-noel/arche-de-noel-flocon',
    title: 'Widddi avec 3d - Arche de Noël',
    description: 'Créez votre boule de Noël personnalisée en suivant les 5 étapes ci-dessous.',
    image: 'christmas/boule-arche-7.jpg',
  },
  {
    name: '/produit/petit-cadre/cadeau-de-remerciement',
    title: 'Widddi avec 3d - Cadeaux de remerciement',
    description: 'Ces petits cadres sont une belle façon de remercier les personnes importantes dans la vie de votre enfant. <br />Vous pouvez y glisser une petite photo ou un mini dessin. Un cadeau original qui plaira à coup sûr.',
    image: 'mini-cadres/enseigner-2.jpg',
  },
  {
    name: '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-enseignant',
    title: 'Widddi avec 3d - Cadeau de remerciement enseignant',
    description: 'Aimer, inspirer, guider, motiver : Les missions essentielles d\'un bon enseignant. Créez un cadeau original pour remercier le maître ou la maîtresse de votre enfant.',
    image: 'mini-cadres/enseigner-2.jpg',
  },
  {
    name: '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-nounou',
    title: 'Widddi avec 3d - Cadeau de remerciement nounou',
    description: 'Amour, patience, douceur, tendresse. Nounou, marraine, mamie,.. Créez un petit cadeau original pour remercier celles et ceux qui s\'occupent tellement bien de votre bout de chou.',
    image: 'mini-cadres/nounou-1.jpg',
  },
  {
    name: '/produit/petit-cadre/cadeau-de-remerciement/cadre-cadeau-remerciement-aesh',
    title: 'Widddi avec 3d - Cadeau de remerciement AESH',
    description: 'Créez un cadeau original pour remercier l\'AESH de votre enfant.',
    image: 'mini-cadres/aesh-1.jpg',
  },
  {
    name: '/produit/cadeau-de-demande-parrain-marraine',
    title: 'Widddi avec 3d - Cadeaux de demande parrain/marraine',
    description: 'Un cadeau original pour faire votre demande à la marraine et au parrain de votre bébé !',
    image: 'cadeau-parrain-marraine/cadeau-demande-parrain-cadre-photo.jpg',
  },
  {
    name: '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-parrain',
    title: 'Widddi avec 3d - Cadre photo de demande parrain',
    description: 'Un cadeau original pour faire votre demande à la marraine de votre bébé ! Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
    image: 'cadeau-parrain-marraine/cadeau-demande-parrain-cadre-photo.jpg',
  },
  {
    name: '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-marraine',
    title: 'Widddi avec 3d - Cadre photo de demande marraine',
    description: 'Un cadeau original pour faire votre demande à la marraine de votre bébé ! Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
    image: 'cadeau-parrain-marraine/cadeau-demande-marraine-cadre-photo.jpg',
  },
  {
    name: '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-parrain-horizontal',
    title: 'Widddi avec 3d - Cadre photo de demande parrain - horizontal',
    description: 'Un cadeau original pour faire votre demande à la marraine de votre bébé ! Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
    image: 'cadeau-parrain-marraine/cadeau-demande-parrain-cadre-photo-horizontale.jpg',
  },
  {
    name: '/produit/cadeau-de-demande-parrain-marraine/cadre-demande-marraine-horizontal',
    title: 'Widddi avec 3d - Cadre photo de demande marraine - horizontal',
    description: 'Un cadeau original pour faire votre demande à la marraine de votre bébé ! Glissez-y une photos de votre échographie ou une photo originale de votre ventre de grossesse.',
    image: 'cadeau-parrain-marraine/cadeau-demande-marraine-cadre-photo-horizontale.jpg',
  },
  {
    name: '/produit/cadre-de-naissance/cadre-de-naissance-horizontal-simple',
    title: 'Widddi avec 3d - Cadre de naissance horizontal simple',
    description: 'Créez votre cadre de naissance en suivant les 5 étapes ci-dessous',
    image: 'cadre-perso-horizontal/cadre-perso-horizontal-17.jpg',
  },
  {
    name: '/produit/noel/decorations-de-noel/boule-de-noel-couronne-de-feuilles',
    title: 'Widddi avec 3d - Boule de Noël Couronne de feuilles',
    description: 'Notre nouvelle boule de Noël mobile vous émerveillera avec ses deux cercles intérieurs qui tournent sur eux-même.',
    image: 'christmas/boule-de-noel-couronne-1.jpg',
  },
  {
    name: '/produit/noel/decorations-de-noel/nuit-etoilee',
    title: 'Widddi avec 3d - Boule de Noël Nuit Étoilée',
    description: 'La boule de Noël Widddi “Nuit Étoilée” vous enveloppe dans la nuit magique de Noël.',
    image: 'christmas/boule-nuit-etoilee-1.jpeg',
  },
  {
    name: '/produit/cadre-photo-personnalise/cadre-de-mariage',
    title: 'Widddi avec 3d - Cadre à poser "Le mariage c\'est"',
    description: 'Le mariage c’est la volonté à deux de créer l’unique. Personnalisez ce cadre photo avec la date du mariage et les initiales des amoureux.',
    image: 'phrase/cadre-personnalise-mariage.jpg',
  },
  {
    name: '/produit/cadre-de-naissance/cadre-de-naissance-arc-en-ciel',
    title: 'Cadre de naissance Arc-en-ciel',
    variant: 'Un souvenir coloré, unique et original de la naissance de votre bébé.',
    image: 'rainbow/cadre-naissance-personnalise-arc-en-ciel.jpg',
  },
  {
    name: '/produit/cadre-photo-personnalise/cadre-de-naissance-arc-en-ciel',
    title: 'Cadre personnalisé Arc-en-ciel',
    variant: 'Un souvenir coloré, unique et original.',
    image: 'rainbow/cadre-arc-en-ciel-personnalise-1.jpg',
  },
];

module.exports = {
  findIndexInData,
  prenderedRoutes,
};
